import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { FaInstagram } from "react-icons/fa"
import ContactForm from "./ContactForm"

const Footer = () => {
  const {
    site: {
      meta: { links },
    },
    address: { address }
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            instagram
          }
        }
      }
      address: contentfulFooter {
        address
      }
    }
  `)

  return (
    <div className="container bg-custombricks py-8">
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end py-8">
        <ContactForm />
        <div className="w-full flex flex-col-reverse justify-between items-end">
          {/*<div className="hidden text-white  text-left lg:text-right">
            { address && address.map((line,index)=>{
              return <p key={`address-line${index}`}>{line}</p>
            })}
          </div>*/}
          <div className="mt-8 md:mt-0 md:order-1 lg:flex lg:flex-col lg:items-end">
          <Link className="flex justify-end mb-8" to="/">
            <img alt="Logo" className="h-20 w-32" src="logo.svg" />
          </Link>
          <p className="text-left lg:text-center text-sm md:text-base text-white">
            &copy; {new Date().getFullYear()} Yae Works Limited. All rights reserved.
          </p>
        </div>
        </div>
      </div>
      <div className="hidden">
        <ul className="justify-center md:order-2 hidden">
          <FooterLink
            href={links.instagram}
            icon={FaInstagram}
            label="Instagram"
          />
        </ul>
      </div>
    </div>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="inline-block pl-6">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="text-gray-500 hover:text-blue-600 transition duration-150 ease-in-out"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-5 h-5 fill-current" />
      </a>
    </li>
  )
}

export default Footer
